import { getBrandLang } from '@prismamedia/one-brandkey';
import { BrandKey } from '../__generated__/queries-web';
import { useGetBrandPrompts } from '../apollo/queries/prompts.web.graphql';
import {
  BRAND_KEY_LANGUAGE,
  GENERATE_PROMPT,
  PARAPHRASE_PROMPT,
} from '../components/TextSuggestion/constants';

interface Prompt {
  label: string;
  body: string;
  field?: string;
  retry?: boolean;
}

export const useOpenAiPrompts = ({
  brandKey,
  body = '',
  lead = '',
  title = '',
}: {
  brandKey: BrandKey;
  body?: string;
  lead?: string;
  title?: string;
}): Prompt[] => {
  const brandLang = BRAND_KEY_LANGUAGE[getBrandLang(brandKey)];
  const { data } = useGetBrandPrompts(brandKey);

  if (!data?.prompts?.length) {
    return [
      GENERATE_PROMPT(brandLang, title),
      PARAPHRASE_PROMPT(brandLang, title),
    ];
  }

  const prompts = data.prompts.map((prompt) => {
    const field =
      prompt.field === 'Clickbait'
        ? 'partnerTitle'
        : prompt.field.toLowerCase();

    const hasField = field && prompt.body.includes(`\${${field}}`);
    const hasAnyVariable = prompt.body.includes('${');
    return {
      label: prompt.label,
      body: prompt.body
        .replace(/\$\{body\}/g, body)
        .replace(/\$\{lead\}/g, lead)
        .replace(new RegExp(`\\$\\{${field}\\}`, 'g'), '${currentField}'),
      field,
      retry: hasField || !hasAnyVariable,
    };
  });

  return prompts;
};
