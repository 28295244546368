export const GENERATE_PROMPT = (lang: string, title: string) => ({
  label: 'Générer',
  body: `Écris en ${lang}. Tu es un journaliste. Créé un titre qui soit un incitatif. Règles importantes : 1 : Les titres ne doivent jamais dépasser 60 caractères, espace inclus. 2 : respecte la ponctuation de la langue ${lang} : Pas d’espace avant, une espace après : point, virgule, points de suspension, parenthèse fermante, crochet fermant, guillemets anglais fermants. Une espace avant, pas d’espace après : parenthèse ouvrante, crochet ouvrant, guillemets anglais ouvrants. Une espace avant, une espace après : Tiret d’incise. Une espace insécable avant, une espace après : deux-points, point-virgule, point d’exclamation, point d’interrogation, guillemets français fermants, pourcentage, signes mathématiques. Une espace avant, une espace insécable après : guillemets français ouvrants. Mettre une majuscule à chaque début de phrase. Les noms propres prennent toujours une majuscule. Les prénoms, noms et surnoms prennent toujours une majuscule. Les noms de peuples ou d’habitants prennent toujours une majuscule. On ne met pas de majuscule aux adjectifs adjoints aux noms propres géographiques. 3 : Si tu utilises le terme Clickbait dans ta réponse, utilise plutôt le mot "Incitatif". La syntaxe doit-être grammaticalement parfaite et donner envie de lire le contenu Voici le titre : ${title}`,
  field: 'partnerTitle',
});

export const PARAPHRASE_PROMPT = (lang: string, title: string) => ({
  label: 'Paraphraser',
  body: `Écris en ${lang}. Tu es un journaliste. Créé une version paraphrasée du titre en conservant la même longueur et le style que le titre d’origine. Règles importantes : 1 : Les titres ne doivent jamais dépasser 60 caractères, espace inclus. 2 : respecte la ponctuation de la langue ${lang} : Pas d’espace avant, une espace après : point, virgule, points de suspension, parenthèse fermante, crochet fermant, guillemets anglais fermants. Une espace avant, pas d’espace après : parenthèse ouvrante, crochet ouvrant, guillemets anglais ouvrants. Une espace avant, une espace après : Tiret d’incise. Une espace insécable avant, une espace après : deux-points, point-virgule, point d’exclamation, point d’interrogation, guillemets français fermants, pourcentage, signes mathématiques. Une espace avant, une espace insécable après : guillemets français ouvrants. Mettre une majuscule à chaque début de phrase. Les noms propres prennent toujours une majuscule. Les prénoms, noms et surnoms prennent toujours une majuscule. Les noms de peuples ou d’habitants prennent toujours une majuscule. On ne met pas de majuscule aux adjectifs adjoints aux noms propres géographiques. 3 : Si tu utilises le terme Clickbait dans ta réponse, utilise plutôt le mot "Incitatif". La syntaxe doit-être grammaticalement parfaite et donner envie de lire le contenu Voici le titre : ${title}`,
  field: 'partnerTitle',
  retry: true,
});

export enum BRAND_KEY_LANGUAGE {
  DE = 'allemand',
  EN = 'anglais',
  ES = 'espagnol',
  FR = 'français',
  IT = 'italien',
  PT = 'portugais',
}
